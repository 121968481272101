@use 'sass:map';
@import 'colors';

tui-dialog-host {
  .t-heading {
    display: none !important;
  }

  .t-overlay {
    background: var(--tui-base-04) !important;

    &.t-overlay_visible {
      opacity: 0.8 !important;
    }
  }

  tui-dialog {
    border-radius: var(--radius-m) !important;
    max-height: 100vh;
    border: 2rem solid transparent !important;

    &::after {
      box-shadow: none !important;
    }

    > .t-content {
      max-height: 100%;
      overflow: auto;
      padding: 0 !important;
      position: relative;
      font: var(--tui-font-text-m);
      background: var(--background-secondary) !important;
      box-shadow: 0 2px 4px rgba(map.get($colors, 'support-2-700'), 0.3),
        0 15px 35px rgba(map.get($colors, 'support-2-700'), 0.1);

      .dia-header {
        position: sticky;
        top: 0;
        z-index: 1;
        color: var(--tui-base-09);
        padding: 2rem 3rem;
        user-select: none;
      }

      .dia-sidebar {
        padding: 3rem 3rem 2rem;
        border-right: 1px solid var(--tui-base-03);
      }

      .dia-body {
        padding: 3rem 3rem 1rem;
      }

      .dia-footer {
        position: sticky;
        bottom: 0;
        z-index: 1;
        background: inherit;
        padding: 2rem 3rem;
      }
    }
  }
}
