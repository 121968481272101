@import 'textfield';
@import 'dialog';
@import 'wrapper';
@import 'notification';
@import 'colors';

:root {
  // TUI customizations for all themes.
  --tui-radius-m: var(--radius-s);
  --tui-radius-l: var(--radius-l);
  --tui-font-heading-1: 900 3.125rem/3.5rem var(--tui-font-heading);

  // Transfer colors from SCSS to CSS.
  @each $name, $color in $colors {
    // CSS variables
    #{'--' + $name}: $color !important;
  }
}

.tui-skeleton {
  border-radius: var(--radius-s);
  overflow: hidden;
}

[tuiWrapper]:disabled:disabled,
[tuiWrapper][data-state='disabled'][data-state='disabled'] {
  opacity: 1;
  pointer-events: none;
  background: var(--tui-base-02);
  color: var(--tui-base-06);
}

/*
Use gap instead of individual margins that cause layout problems.
*/
.t-left,
.t-right {
  margin: unset !important;
}

.t-content {
  gap: 0.25rem;
}

hr {
  height: 1px;
}

.tui-text_body-m a {
  color: var(--primary-700);

  &:active {
    color: var(--primary-900);
  }
}

tui-tabs {
  --tui-height-l: 42px;

  box-sizing: content-box;
  padding-bottom: 16px;
  margin-bottom: 0;
  box-shadow: none !important;

  > a {
    display: flex;
    border-radius: calc(var(--tui-height-l) / 2);
    padding: 0 calc(var(--tui-height-l) / 2) !important;
    margin-right: 0.5rem !important;
    margin-left: 0 !important;

    &:hover {
      background-color: var(--tui-base-03);
    }

    &:active {
      background-color: var(--tui-base-03);
    }

    tui-svg {
      margin-right: 8px;
    }

    tui-svg,
    span {
      color: var(--tui-base-08);
    }

    &.active {
      background-color: var(--tui-base-03);

      tui-svg,
      span {
        color: var(--tui-base-09);
      }
    }
  }
}

tui-svg {
  &.tui-icon-s {
    width: 20px !important;
    height: 20px !important;
  }

  &.tui-icon-m {
    width: 45px !important;
    height: 45px !important;
  }

  &.tui-icon-m2 {
    width: 55px !important;
    height: 55px !important;
  }

  &.tui-icon-l {
    width: 62px;
    height: 60px;
  }

  &.tui-icon-l2 {
    width: 70px;
    height: 70px;
  }

  &.tui-icon-xl {
    width: 105px;
    height: 105px;
  }
}

button[tuibutton][appearance='primary'] {
  --tui-focus: var(--primary-700);
}

.tui-text_h3 {
  font-weight: 300;
}

.tui-text_body-xl {
  font-weight: 600;
}

tui-hint-box {
  border-radius: var(--radius-s) !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 16%);
  user-select: none;
}

label .label .text {
  font-weight: bold;
}

.tui-label-m {
  font-size: 1rem;
  line-height: 1.2;
}

tui-input,
tui-input-password {
  font-weight: 300;
  width: 100%;
}

tui-tag {
  border-radius: var(--radius-s);
}

.tui-island_size_m {
  padding: 16px;
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: none !important;
}

tui-dropdown {
  border: none !important;
}

.inline-links a {
  color: var(--tui-text-03);

  &:active {
    color: var(--primary-600);
  }

  &:hover {
    text-decoration: underline;
  }
}

tui-dropdown-host {
  // This TaigaUI placeholder has an incorrect width. In the component `select-company`,
  // the unselected company would have less width available than the selected one, even
  // though there would be more space because of the lack of an icon.
  .t-dummy {
    display: none !important;
  }
}

// Create a hairline border on dark theme.
.hairline {
  outline: var(--hairline);
}
