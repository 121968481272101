[tuiWrapper][data-appearance='outline'] {
  &::after {
    color: var(--tui-base-03);
  }

  /* stylelint-disable selector-class-pattern */
  &._focused._focused::after {
    border-width: 1px;
    color: var(--tui-base-04);
  }

  &[data-state='disabled'] {
    background-color: var(--tui-base-02);

    input {
      cursor: default;
    }
  }
}

label.t-placeholder {
  font-weight: bold !important;
}

[tuiWrapper][data-appearance='textfield'][data-state='readonly'][data-state='readonly']::after {
  color: var(--tui-base-03);
}

// Prevent TUI from changing the focus color.
[tuiWrapper][data-appearance='textfield']._focused._focused {
  --tui-focus: unset;
  --tui-duration: inherit;
}

// Prevent TUI from changing the duration.
tui-primitive-textfield {
  --tui-duration: inherit;
}
