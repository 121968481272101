@mixin focusable($color: var(--tui-focus)) {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
  }

  &::before {
    opacity: 0.2;
  }

  &:focus,
  &:focus-within {
    &::before {
      box-shadow: 0 0 0 0.4rem $color;
    }

    &::after {
      outline: 0.1rem solid $color;
    }
  }
}
