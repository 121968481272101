@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'tui-themes/typography';
@import 'tui-themes/customization';
@import 'table';

:root {
  --radius-s: 0.375rem;
  --radius-m: 0.5rem;
  --radius-l: 1rem;
  --radius-xl: 28px;
}

html,
body {
  box-sizing: border-box;
  min-width: 375px;
  background: var(--background-main);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a,
button:not(:disabled) {
  cursor: pointer;
}

.radius-s {
  border-radius: var(--radius-s);
}

.radius-m {
  border-radius: var(--radius-m);
}

.radius-l {
  border-radius: var(--radius-l);
}

.radius-xl {
  border-radius: var(--radius-xl);
}

.box-shadow {
  box-shadow: var(--box-shadow-1);
}
