@import 'focusable';

[tuiWrapper][data-appearance='flat'] {
  color: var(--tui-base-08);

  &:hover {
    color: var(--tui-base-09) !important;
  }
}

.focusable {
  @include focusable(var(--tui-focus));
}

a,
button {
  &[tuibutton],
  &[tuiiconbutton] {
    font-weight: 600 !important;

    @include focusable(var(--tui-focus));

    &.danger {
      background: var(--tui-error-bg);

      @include focusable(var(--error-600));

      [tuiWrapper][data-appearance='outline']::after {
        color: var(--error-600);
        opacity: 0.4;
      }

      .t-content {
        color: var(--error-600);
      }
    }
  }
}

[tuiWrapper][data-appearance='textfield'] {
  background: unset;
}

button[tuibutton][appearance='flat'] {
  &[ng-reflect-tui-dropdown-manual='true'] .t-wrapper {
    background: var(--tui-secondary-hover);
  }
}
/* stylelint-disable selector-class-pattern */
tui-hosted-dropdown._hosted_dropdown_focused {
  button[tuibutton],
  button[tuiiconbutton] {
    &[appearance='flat'] {
      .t-wrapper {
        background: var(--tui-secondary-hover);
      }
    }
  }
}
