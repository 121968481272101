@use 'sass:meta';

:root {
  $font-family-sans-serif: -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
    'Noto Sans', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';

  --tui-heading-font: #{meta.inspect($font-family-sans-serif)};
  --tui-text-font: #{meta.inspect($font-family-sans-serif)};
}

.color-accent {
  color: var(--tui-primary);
}
