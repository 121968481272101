.s1s-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 1.5em;

  thead {
    text-transform: uppercase;
  }

  tbody {
    tr {
      box-shadow: var(--shadow-1);
      border-radius: var(--tui-radius-l);
    }
  }

  th {
    padding: 0 1.25rem;
    font-size: var(--tui-font-text-s);
  }

  td {
    margin: 0 0 1.5rem;
    background-color: var(--background-secondary);
    vertical-align: middle;
    height: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1.25rem;
    align-items: center;
    box-sizing: content-box;

    &:first-child {
      border-top-left-radius: var(--tui-radius-l);
      border-bottom-left-radius: var(--tui-radius-l);
    }

    &:last-child {
      border-top-right-radius: var(--tui-radius-l);
      border-bottom-right-radius: var(--tui-radius-l);
    }
  }
}
